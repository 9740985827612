import HomePage from "./PublicLayout/HomePage/HomePage";
import Campaigns from "./PublicLayout/Campaigns/Campaigns";
import CampaignsDetails from "./PublicLayout/Campaigns/CampaignsDetails/CampaignsDetails";
import AllCampaigns from "./PublicLayout/Campaigns/AllCampaigns/AllCampaigns";
import News from "./PublicLayout/News/News";
import NewsDetails from "./PublicLayout/News/NewsDetails/NewsDetails";
import MemberWorkplaces from "./PublicLayout/MemberWorkplaces/MemberWorkplaces";
import MemberWorkplacesDetails from "./PublicLayout/MemberWorkplaces/MemberWorkplacesDetails/MemberWorkplacesDetails";
import FAQ from "./PublicLayout/FAQ/FAQ";
import TuzlaKartInfo from "./PublicLayout/TuzlaKartInfo/TuzlaKartInfo";
import GencKartInfo from "./PublicLayout/GencKartInfo/GencKartInfo";
import Callback from "./PublicLayout/Callback/Callback";

import Login from "./AdminLayout/AuthenticationLayout/Login/Login";
import Register from "./AdminLayout/AuthenticationLayout/Register/Register";
import ForgotPassword from "./AdminLayout/AuthenticationLayout/ForgotPassword/ForgotPassword";

import TKCardHolders from "./AdminLayout/MainLayout/TKCardHolders/TKCardHolders";
import TKMemberWorkplaces from "./AdminLayout/MainLayout/TKMemberWorkplaces/TKMemberWorkplaces";
import TKNews from "./AdminLayout/MainLayout/TKNews/TKNews";
import TKCampaigns from "./AdminLayout/MainLayout/TKCampaigns/TKCampaigns";
import TKCategories from "./AdminLayout/MainLayout/TKCategories/TKCategories";
import TKSettings from "./AdminLayout/MainLayout/TKSettings/TKSettings";
import TKBanners from "./AdminLayout/MainLayout/TKBanners/TKBanners";
import TKComplains from "./AdminLayout/MainLayout/TKComplains/TKComplains";
import TKFAQ from "./AdminLayout/MainLayout/TKFAQ/TKFAQ";
import TKTKN from "./AdminLayout/MainLayout/TKTKN/TKTKN";
import TKGKN from "./AdminLayout/MainLayout/TKGKN/TKGKN";
import TKAdminUsers from "./AdminLayout/MainLayout/TKAdminUsers/TKAdminUsers";
import TKApiUsers from "./AdminLayout/MainLayout/TKApiUsers/TKApiUsers";
import TKOrganizations from "./AdminLayout/MainLayout/TKOrganizations/TKOrganizations";
import TKCardTypes from "./AdminLayout/MainLayout/TKCardTypes/TKCardTypes";
import TKAdminPage from "./AdminLayout/MainLayout/TKAdminPage/TKAdminPage";
import TKProfession from "./AdminLayout/MainLayout/TKProfession/TKProfession";
import TKTransactions from "./AdminLayout/MainLayout/TKTransactions/TKTransactions";
import TKLinks from "./AdminLayout/MainLayout/TKLinks/TKLinks";

export {
  HomePage,
  Campaigns,
  CampaignsDetails,
  AllCampaigns,
  News,
  NewsDetails,
  MemberWorkplaces,
  MemberWorkplacesDetails,
  FAQ,
  TuzlaKartInfo,
  GencKartInfo,
  Login,
  Register,
  ForgotPassword,
  TKCampaigns,
  TKMemberWorkplaces,
  TKCardHolders,
  TKNews,
  TKCategories,
  TKSettings,
  TKBanners,
  TKComplains,
  TKFAQ,
  TKTKN,
  TKGKN,
  TKAdminUsers,
  TKApiUsers,
  TKOrganizations,
  TKCardTypes,
  TKAdminPage,
  TKProfession,
  TKTransactions,
  TKLinks,
  Callback,
};
