import {
  PublicLayout,
  AuthenticationLayout,
  MainLayout,
} from "./containers/layouts";

import { Route } from "react-router-dom";
import PrivateRoute from "./containers/routes/PrivateRoute";

import {
  HomePage,
  Campaigns,
  CampaignsDetails,
  AllCampaigns,
  News,
  NewsDetails,
  MemberWorkplaces,
  MemberWorkplacesDetails,
  FAQ,
  TuzlaKartInfo,
  GencKartInfo,
  Login,
  Register,
  ForgotPassword,
  TKCampaigns,
  TKMemberWorkplaces,
  TKCardHolders,
  TKNews,
  TKCategories,
  TKSettings,
  TKBanners,
  TKComplains,
  TKFAQ,
  TKTKN,
  TKGKN,
  TKAdminUsers,
  TKApiUsers,
  TKOrganizations,
  TKCardTypes,
  TKAdminPage,
  TKProfession,
  TKTransactions,
  TKLinks,
  Callback,
} from "./containers/pages";
import MyAccount from "./containers/pages/PublicLayout/MyAccount/MyAccount";

const PUBLIC_BASE_URL = "/";

const ADMIN_CARD_HOLDERS_URL = "/admin/card-holders";
const ADMIN_MEMBER_WORKPLACES_URL = "/admin/member-workplaces";
const ADMIN_CAMPAIGNS_URL = "/admin/campaigns";
const ADMIN_NEWS_URL = "/admin/news";
const ADMIN_BANNERS_URL = "/admin/banners";
const ADMIN_CATEGORIES_URL = "/admin/categories";
const ADMIN_SETTINGS_URL = "/admin/settings";
const ADMIN_LOGIN_URL = "/admin/login";
const ADMIN_FORGOTPASSWORD_URL = "/admin/forgotpassword";
const ADMIN_REGISTER_URL = "/admin/register";
const ADMIN_COMPLAIN_URL = "/admin/complains";
const ADMIN_FAQ_URL = "/admin/faq";
const ADMIN_TKN_URL = "/admin/tkn";
const ADMIN_GKN_URL = "/admin/gkn";
const ADMIN_ADMIN_USERS_URL = "/admin/admin-users";
const ADMIN_ADMIN_API_URL = "/admin/api-users";
const ADMIN_MAINPAGE_API_URL = "/admin/mainpage";
const ADMIN_ORGANIZATION_API_URL = "/admin/organizations";
const ADMIN_CARDTYPES_API_URL = "/admin/cardtypes";
const ADMIN_PROFESSIONS_API_URL = "/admin/professions";
const ADMIN_TRANSACTIONS_API_URL = "/admin/transactions";
const ADMIN_LINKS_API_URL = "/admin/links";

const routes = {
  PublicLayout: {
    component: PublicLayout,
    children: [
      {
        path: PUBLIC_BASE_URL,
        exact: true,
        component: HomePage,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}hesabim`,
        exact: true,
        component: MyAccount,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}kampanyalar`,
        exact: true,
        component: Campaigns,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}kampanyalar/all`,
        exact: false,
        component: AllCampaigns,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}kampanyalar/:id`,
        exact: false,
        component: CampaignsDetails,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}haberler`,
        exact: true,
        component: News,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}haberler/:id`,
        exact: false,
        component: NewsDetails,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}uye-firmalar`,
        exact: true,
        component: MemberWorkplaces,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}uye-firmalar/:id`,
        exact: false,
        component: MemberWorkplacesDetails,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}faq`,
        exact: true,
        component: FAQ,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}tuzla-kart-nedir`,
        exact: true,
        component: TuzlaKartInfo,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}genc-kart-nedir`,
        exact: true,
        component: GencKartInfo,
        routeComponent: Route,
      },
      {
        path: `${PUBLIC_BASE_URL}callback`,
        exact: true,
        component: Callback,
        routeComponent: Route,
      },
    ],
  },
  MainLayout: {
    component: MainLayout,
    children: [
      {
        path: ADMIN_MAINPAGE_API_URL,
        exact: true,
        component: TKAdminPage,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_CARD_HOLDERS_URL,
        exact: true,
        component: TKCardHolders,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_MEMBER_WORKPLACES_URL,
        exact: true,
        component: TKMemberWorkplaces,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_CAMPAIGNS_URL,
        exact: true,
        component: TKCampaigns,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_NEWS_URL,
        exact: true,
        component: TKNews,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_BANNERS_URL,
        exact: true,
        component: TKBanners,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_CATEGORIES_URL,
        exact: true,
        component: TKCategories,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_SETTINGS_URL,
        exact: true,
        component: TKSettings,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_COMPLAIN_URL,
        exact: true,
        component: TKComplains,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_FAQ_URL,
        exact: true,
        component: TKFAQ,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_TKN_URL,
        exact: true,
        component: TKTKN,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_GKN_URL,
        exact: true,
        component: TKGKN,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_ADMIN_USERS_URL,
        exact: true,
        component: TKAdminUsers,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_ADMIN_API_URL,
        exact: true,
        component: TKApiUsers,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_ORGANIZATION_API_URL,
        exact: true,
        component: TKOrganizations,
        routeComponent: PrivateRoute,
      },
      ,
      {
        path: ADMIN_CARDTYPES_API_URL,
        exact: true,
        component: TKCardTypes,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_PROFESSIONS_API_URL,
        exact: true,
        component: TKProfession,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_TRANSACTIONS_API_URL,
        exact: true,
        component: TKTransactions,
        routeComponent: PrivateRoute,
      },
      {
        path: ADMIN_LINKS_API_URL,
        exact: true,
        component: TKLinks,
        routeComponent: PrivateRoute,
      },
    ],
  },
  AuthenticationLayout: {
    component: AuthenticationLayout,
    children: [
      {
        path: ADMIN_LOGIN_URL,
        component: Login,
        routeComponent: Route,
      },
      {
        path: ADMIN_FORGOTPASSWORD_URL,
        component: ForgotPassword,
        routeComponent: Route,
      },
      {
        path: ADMIN_REGISTER_URL,
        component: Register,
        routeComponent: Route,
      },
    ],
  },
};

export default routes;
