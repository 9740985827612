import React, { useEffect, useState } from "react";
import axios from "axios";
import TKSignupForm from "../../../../components/TKLoginContainer/TKSignupForm/TKSignupForm";
import { useAuth } from "../../../../context/authContext";
import { Modal } from "react-bootstrap";
import TKValidationForm from "../../../../components/TKLoginContainer/TKValidationForm/TKValidationForm";

const API_URL = process.env.REACT_APP_API_URL;

const CONFIG = {
  API_URL: API_URL,
  CALLBACK_URL: "https://tuzlakart.com/?app=TuzlaSenin",
};

const Callback = ({ type = "web" }) => {
  const queryString = new URLSearchParams(window.location.href);
  const [code, setCode] = useState(queryString.get("code"));
  const [userMidentity, setUserMidentity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const isAuth = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [field, setField] = useState("signup");

  const handleShowModal = (show, field) => {
    setField(field);
    setShowModal(show);
  };

  useEffect(() => {
    user && setShowModal(true);
  }, [user]);

  console.log(field);

  useEffect(() => {
    const authorize = () => {
      if (!code) {
        setLoading(true);
        axios
          .get(
            CONFIG.API_URL +
              "token/auth/oidc?type=" +
              type +
              "&redirect_uri=" +
              CONFIG.CALLBACK_URL
          )
          .then((response) => {
            window.location.href = response.data.composed_url;
          })
          .catch((error) => {});
      } else {
        if (!userMidentity) {
          axios
            .post(CONFIG.API_URL + "token/auth/code?type=" + type, {
              code: code,
              redirect_uri: CONFIG.CALLBACK_URL,
            })
            .then((response) => {
              if (response.data?.user) {
                setUser(response.data?.user);
              } else {
                localStorage.setItem("token", response.data.access_token);
                localStorage.setItem("userRole", response.data.role);
                window.location.href = "/";
              }
            })
            .catch((error) => {})
            .finally(setLoading(false));
        } else {
          return;
        }
      }
    };
    authorize();
  }, [code]);

  return (
    <div>
      {loading ? (
        <h1>loading</h1>
      ) : (
        <div style={{ marginTop: "150px" }}>
          {user && (
            <Modal
              className="tk_login-signup-modal"
              show={showModal}
              onHide={(h) => setShowModal(h)}
              size={field === "userInfo" ? "lg" : "md"}
              centered
              enforceFocus={false}
            >
              <Modal.Body className="p-0">
                {field === "signup" && (
                  <TKSignupForm
                    setShowModal={(p) => setShowModal(p)}
                    handleShowModal={handleShowModal}
                    registerData={{
                      name: user.given_name,
                      surname: user.family_name,
                      email: user.email,
                      tckn: user?.tckn,
                      birthdate: new Date(
                        ...user?.birthdate
                          .split(".")
                          .reverse()
                          .map((v, i) => (i === 1 ? v - 1 : v))
                      ),
                      telephone_number: user?.mobile,
                    }}
                  />
                )}
                {field === "validation" && (
                  <TKValidationForm
                    setShowModal={(p) => setShowModal(p)}
                    handleShowModal={handleShowModal}
                  />
                )}
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default Callback;
