// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/display-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tk_page-header {\n  margin: 80px 0 0px; }\n\n.centered {\n  top: 50%;\n  left: 50%; }\n\n.tk_display-text {\n  position: absolute;\n  bottom: 0;\n  top: 50%;\n  color: #fff; }\n\n.header-image {\n  z-index: -1;\n  position: relative;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: auto;\n  height: 400px;\n  background-position-x: center;\n  background-position-y: center;\n  background-repeat: repeat-x; }\n", "",{"version":3,"sources":["webpack://src/components/TKPageHeader/TKPageHeader.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;;AAIpB;EACE,QAAQ;EACR,SAAS,EAAA;;AAGX;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW,EAAA;;AAGb;EACE,WAAW;EACX,kBAAkB;EAClB,yDAA2D;EAC3D,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,6BAA6B;EAC7B,2BAA2B,EAAA","sourcesContent":[".tk_page-header {\n  margin: 80px 0 0px;\n \n}\n\n.centered {\n  top: 50%;\n  left: 50%;\n}\n\n.tk_display-text{\n  position: absolute;\n  bottom: 0;\n  top: 50%;\n  color: #fff;\n}\n\n.header-image {\n  z-index: -1;\n  position: relative;\n  background-image: url('../../assets/images/display-bg.png');\n  width: auto;\n  height: 400px;\n  background-position-x: center;\n  background-position-y: center;\n  background-repeat: repeat-x;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
